import { Injectable } from '@angular/core';
import { DataService, ProcService, MainService } from './';

@Injectable()
export class EventService {

    constructor(
        public dataService: DataService,
        private procService: ProcService,
        private mainService: MainService
    ) { }

    

}