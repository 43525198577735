import { Injectable } from '@angular/core';
import { UtilisateurOption } from '../models';

@Injectable()
export class OptionsService {

  getOption(options: any, identifier: string): string {
    let option: any;

    if (undefined === options) {
      return '';
    }

    if (options.listChampIhmOptions) {
      option = options.listChampIhmOptions.find((f) => f.optionIdentifier === identifier);
    } else if (options.listChampEtatOptions) {
      option = options.listChampEtatOptions.find((f) => f.optionIdentifier === identifier);
    } else {
      option = options.find((f) => f.optionIdentifier === identifier);
    }

    if (undefined === option) {
      return '';
    }
    return option.optionValue;
  }

  setOption(options: any, identifier: string, value: string): void {
    let option: any;

    if (undefined === options) {
      return;
    }

    if (options.listChampIhmOptions) {
      option = options.listChampIhmOptions.find((f) => f.optionIdentifier === identifier);
    } else if (options.listChampEtatOptions) {
      option = options.listChampEtatOptions.find((f) => f.optionIdentifier === identifier);
    } else {
      option = options.find((f) => f.optionIdentifier === identifier);
    }

    if (option !== undefined) {
      option.optionValue = value;
    }
  }

  addOption(identifier, value): any {
    const option: UtilisateurOption = { };
    option.optionIdentifier = identifier;
    option.optionValue = value;
    return option;
  }
}
