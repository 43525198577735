import { Item, PrivateCell } from '.';

export class PrivateRow {
    constructor(
        public id?: number,
        public libelle?: string,
        public parentId?: number,
        public userId?: number,
        public ordre?: number,
        public reference?: string,
        public iditem?: number,
        public arbre?: string,
        public appref?: string,
        public itemref?: string,
        public itemarbre?: string,
        public rowvisible?: number,
        public enroule?: number,
        public listPrivateCell?: Array<PrivateCell>,
        public racineid?: number,
        public staticid?: number,
        public racineitem?: Item,
        public idap?: number,
        public item?: Item
    ) {
        this.listPrivateCell = [];
    }
}
