import { UserQuery } from '.';
// import { UserQuery } from './user-query.model';

export class Item {
  constructor(
    public id?: number,
    public idapp?: number,
    public idtable?: number,
    public appid?: number,
    public appref?: string,
    public libelle?: string,
    public parentId?: number,
    public arbre?: string,
    // public zones?: Array<Zone>,
    public numeroLigne?: number,
    // public listZoneIhm?: Array<ZoneIhm>,
    public listUserQuery?: Array<UserQuery>,
    public isquery?: boolean,
    public ordre?: number,
    public reference?: string,
    public listItems: Array<Item> = [],
    public listChampNatif: Array<any> = [],
    // public listChampIhm: Array<ChampIhm> = [],
    ) {
      this.listUserQuery = [];
    }
  }
