import { TypeUtilisateurEnum, UtilisateurOption, Droit } from '.';

export class Utilisateur {
    constructor(
        public id?: number,
        public reference?: string,
        public type?: TypeUtilisateurEnum,
        public idproject?: number,
        public ordre?: number,
        public listUtilisateurOptions?: Array<UtilisateurOption>,
        public listUtilisateur?: Array<Utilisateur>,
        public listDroit?: Array<Droit>,
        public parentId?: number,
        public libelle?: string,

    ) {
        this.listUtilisateur = [];
        this.listUtilisateurOptions = [];
        this.listDroit = [];
    }
}
