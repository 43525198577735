import { RoundType } from './round-type.enum';
import { CalculationExpression } from './calculationExpression.model';
import { ReturnType } from './return-type.enum';

export const functions = [
  'si(',
  'egal(',
  'eq(',
  'le(',
  'lt(',
  'ge(',
  'gt(',
  'ne(',
  'sqrt(',
  'ou(',
  'et(',
  'not(',
  'pow(',
  'root(',
  'gauche(',
  'droite(',
  'string(',
  'long(',
  'double(',
  'date.now(',
  'time.now(',
  'datetime.now(',
  'date.addDays(',
  'date.removeDays(',
  'date.addMonths(',
  'date.removeMonths(',
  'date.addYears(',
  'date.removeYears(',
  'time.addSeconds(',
  'time.removeSeconds(',
  'time.addMinutes(',
  'time.removeMinutes(',
  'time.addHours(',
  'time.removeHours(',
  'datetime.addSeconds(',
  'datetime.removeSeconds(',
  'datetime.addMinutes(',
  'datetime.removeMinutes(',
  'datetime.addHours(',
  'datetime.removeHours(',
  'datetime.addDays(',
  'datetime.removeDays(',
  'datetime.addMonths(',
  'datetime.removeMonths(',
  'datetime.addYears(',
  'datetime.removeYears(',
  'somme(',
  'sum(',
  'concat(',
  'int.low(',
  'int.up(',
  'int(',
  'round(',
  'round.low(',
  'round.up(',
  'min(',
  'max(',
  'random(',
  'pi(',
  'exp(',
  'log(',
  'ln(',
  'cos(',
  'sin(',
  'tan(',
  'acos(',
  'asin(',
  'atan(',
  'cos.deg(',
  'sin.deg(',
  'tan.deg(',
  'acos.deg(',
  'asin.deg(',
  'atan.deg(',
  'cosh(',
  'sinh(',
  'tanh(',
  'acosh(',
  'asinh(',
  'atanh('
];

export const operators = [
  '!',
  '&&',
  '||',
  '==',
  '!=',
  '<',
  '<=',
  '>',
  '>=',
  '-',
  '+',
  '*',
  '/',
  '%'
];

export const quotations = [
  '"',
  '\''
];

export function roundIfNeeded(expression: number, round: boolean, precision: number, roundType: RoundType = RoundType.NEAR): number {
  if (round) {
    const corrector = Math.pow(10, precision);
    switch (roundType) {
      case RoundType.LOW:
        return Math.floor(expression * corrector) / corrector;

      case RoundType.UP:
        return Math.ceil(expression * corrector) / corrector;

      case RoundType.NEAR:
        return Math.round(expression * corrector) / corrector;

      default:
        throw new SyntaxError('Unreachable state');
    }
  } else {
    return expression;
  }
}

export function isTruthy(expression: CalculationExpression): boolean {
  switch (expression.type) {
    case ReturnType.INT:
    case ReturnType.DOUBLE:
      return Boolean(Number(expression.value) !== 0);

    default:
      return Boolean(expression.value && expression.value !== 'false');
  }
}

export function removeQuotesIfNeeded(expression: string): string {
  if (expression && expression[0] === expression[expression.length - 1] && quotations.indexOf(expression[0]) > -1) {
    return expression.substring(1, expression.length - 1);
  } else {
    return expression;
  }
}

export function addQuotesIfNeeded(expression: string, inQuote: boolean): string {
  if (inQuote) {
    return expression;
  } else {
    return `'${expression}'`;
  }
}

export function explicitDouble(expression: string): string {
  return expression;
}

export function parseDate(expression: string): Date {
  const dateValues = expression.split('-');
  if (dateValues.length === 3) {
    return new Date(Number(dateValues[0]), Number(dateValues[1]) - 1, Number(dateValues[2]));
  } else {
    throw new SyntaxError('Invalid date format');
  }
}

export function parseTime(expression: string): Date {
  const now = new Date();
  const dateValues = expression.split(':');
  if (dateValues.length === 3) {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), Number(dateValues[0]), Number(dateValues[1]), Number(dateValues[2]));
  } else {
    throw new SyntaxError('Invalid time format');
  }
}

export function parseDateTime(expression: string): Date {
  const dateTimeValues = expression.split(' ');
  if (dateTimeValues.length === 2) {
    const dateValues = dateTimeValues[0].split('-');
    const timeValues = dateTimeValues[1].split(':');
    if (dateValues.length === 3 && timeValues.length === 3) {
      return new Date(
        Number(dateValues[0]), Number(dateValues[1]) - 1, Number(dateValues[2]),
        Number(timeValues[0]), Number(timeValues[1]), Number(timeValues[2]));
    }
  }
  throw new SyntaxError('Invalid dateTime format');
}
