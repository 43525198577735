import { TypeDroitEnum, DroitOption } from '.';

export class Droit {
    constructor(
        public id?: number,
        public type?: TypeDroitEnum,
        public ordre?: number,
        public listDroitOptions?: Array<DroitOption>,
        public listDroit?: Array<Droit>,
        public parentId?: number,
        public libelle?: string,

    ) {
        this.listDroit = [];
        this.listDroitOptions = [];
    }
}
