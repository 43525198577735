import { Injectable } from '@angular/core';
import { DataService, DateService, MainService } from './';

@Injectable()
export class ProcService {

  constructor(
    private dataService: DataService,
    private dateService: DateService,
    private mainService: MainService
  ) { }

  

}
