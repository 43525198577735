import { CalculationService } from '../../services/calculation.service';
import {
  functions, quotations, roundIfNeeded, addQuotesIfNeeded, isTruthy, parseDate,
  parseTime, parseDateTime, explicitDouble
} from './calculation.utils';
import { CalculationExpression } from './calculationExpression.model';
import { ReturnType } from './return-type.enum';
import { DatePipe } from '@angular/common';
import { RoundType } from './round-type.enum';

const SEPARATOR = ',';
const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm:ss';
const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export class CalculationFunctionHandlers {
  constructor(
    private calculationService: CalculationService,
    private datePipe: DatePipe
  ) { }

  private wrongNumberOfArgumentsError(func: string, expected: number[], actual: number): string {
    let expectedString = expected[0].toString();
    for (let i = 1; i < expected.length; i++) {
      expectedString = `${expectedString} or ${expected[i]}`;
    }
    return `Wrong number of arguments in function ${func.slice(0, -1).toUpperCase()}, expected ${expectedString} and got ${actual}`;
  }

  private getParameters(expression: string): string[] {
    const params: string[] = [];
    if (expression) {
      let startIndex = 0;
      let parenthesisDepth = 0;
      let quotationType: string;
      let inQuotation = false;
      for (let i = 0; i < expression.length; i++) {
        switch (expression[i]) {
          case '(':
            if (!inQuotation) {
              parenthesisDepth++;
            }
            break;
          case ')':
            if (!inQuotation) {
              parenthesisDepth--;
            }
            break;
          case '"':
          case '\'':
            if (inQuotation) {
              inQuotation = expression[i] !== quotationType;
            } else {
              quotationType = expression[i];
              inQuotation = true;
            }
            break;
          case SEPARATOR:
            if (parenthesisDepth === 0 && !inQuotation) {
              params.push(expression.substring(startIndex, i));
              startIndex = i + 1;
            }
            break;
        }
      }
      params.push(expression.substring(startIndex));
    }
    return params;
  }

  private getEndFunctionIndex(expression: string): number {
    let parenthesisDepth = 1;
    for (let i = 0; i < expression.length; i++) {
      if (expression[i] === '(') {
        parenthesisDepth++;
      } else if (expression[i] === ')') {
        parenthesisDepth--;
        if (parenthesisDepth === 0) {
          return i;
        }
      }
    }
    throw new SyntaxError('Unmatched parenthesis');
  }

  public compareExpressions(func: string, exp1: CalculationExpression, exp2: CalculationExpression): boolean {
    let val1: any;
    let val2: any;
    if ([ReturnType.INT, ReturnType.DOUBLE].indexOf(exp1.type) > -1 && [ReturnType.INT, ReturnType.DOUBLE].indexOf(exp2.type) > -1) {
      val1 = Number(exp1.value);
      val2 = Number(exp2.value);
    } else {
      val1 = exp1.value;
      val2 = exp2.value;
    }
    switch (func) {
      case 'egal(':
      case 'eq(':
        return val1 === val2;
      case 'lt(':
        return val1 < val2;
      case 'le(':
        return val1 <= val2;
      case 'gt(':
        return val1 > val2;
      case 'ge(':
        return val1 >= val2;
      case 'ne(':
        return val1 !== val2;
    }
  }

  private performComparisonFunction(func: string, params: string[], precision: number): boolean {
    if (params.length === 2) {
      const exp1 = this.calculationService.evaluate(params[0], precision, ReturnType.ANY, true);
      const exp2 = this.calculationService.evaluate(params[1], precision, ReturnType.ANY, true);
      return this.compareExpressions(func, exp1, exp2);
    } else {
      throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
    }
  }

  private performCondition(func: string, params: string[], precision: number, inQuote: boolean): CalculationExpression {
    if (params.length === 3) {
      let expression: CalculationExpression;
      if (isTruthy(this.calculationService.evaluate(params[0], precision))) {
        expression = this.calculationService.evaluate(params[1], precision);
      } else {
        expression = this.calculationService.evaluate(params[2], precision);
      }
      if (expression.type === ReturnType.STRING) {
        expression.value = addQuotesIfNeeded(expression.value, inQuote);
      }
      return expression;
    } else {
      throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
    }
  }

  private performNumericalFunction(func: string, params: string[], precision: number, round: boolean): CalculationExpression {
    switch (params.length) {
      case 1:
        const arg = this.calculationService.evaluate(params[0], precision);
        if (func === 'sqrt(') {
          if ([ReturnType.DOUBLE, ReturnType.INT].indexOf(arg.type) > -1) {
            return new CalculationExpression(
              explicitDouble(roundIfNeeded(Math.sqrt(Number(arg.value)), round, precision).toString()),
              ReturnType.DOUBLE
            );
          } else {
            throw new SyntaxError(`Trying to perform numerical function ${func.slice(0, -1).toUpperCase()} on non numerical parameter`);
          }
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
        }
      case 2:
        const arg1 = this.calculationService.evaluate(params[0], precision);
        const arg2 = this.calculationService.evaluate(params[1], precision);
        if ([ReturnType.DOUBLE, ReturnType.INT].indexOf(arg1.type) > -1 && [ReturnType.DOUBLE, ReturnType.INT].indexOf(arg2.type) > -1) {
          if (func === 'pow(') {
            return new CalculationExpression(
              explicitDouble(roundIfNeeded(Math.pow(Number(arg1.value), Number(arg2.value)), round, precision).toString()),
              ReturnType.DOUBLE
            );
          } else if (func === 'root(') {
            return new CalculationExpression(
              explicitDouble(roundIfNeeded(Math.pow(Number(arg1.value), 1 / Number(arg2.value)), round, precision).toString()),
              ReturnType.DOUBLE
            );
          } else {
            throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
          }
        } else {
          throw new SyntaxError(`Trying to perform numerical function ${func.slice(0, -1).toUpperCase()} on non numerical parameter`);
        }
      default:
        if (func === 'sqrt(') {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
        }
    }
  }

  private performTextFunction(func: string, params: string[], precision: number): string {
    if (params.length > 0) {
      const expression = this.calculationService.evaluate(params[0], precision, ReturnType.STRING).value;
      let length = expression.length;
      if (params.length > 1) {
        length = Math.min(length, Number(this.calculationService.evaluate(params[1], precision, ReturnType.INT).value));
      } else {
        length = Math.min(length, 1);
      }

      switch (func) {
        case 'gauche(':
          return expression.substring(0, length);
        case 'droite(':
          return expression.substring(expression.length - length);
        default:
          throw new SyntaxError(`Unknown function ${func.slice(0, -1).toUpperCase()}`);
      }
    } else {
      throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1, 2], params.length));
    }
  }

  private performDateFunction(func: string, params: string[], precision: number): string {

    switch (params.length) {
      case 0:
        if (func === 'date.now(') {
          return this.datePipe.transform(new Date(), DATE_FORMAT);
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
        }
      case 2:
        const date = parseDate(this.calculationService.evaluate(params[0], precision, ReturnType.STRING).value);
        const unitAmount = Number(this.calculationService.evaluate(params[1], precision, ReturnType.INT).value);
        switch (func) {
          case 'date.adddays(':
            date.setDate(date.getDate() + unitAmount);
            return this.datePipe.transform(date, DATE_FORMAT);
          case 'date.removedays(':
            date.setDate(date.getDate() - unitAmount);
            return this.datePipe.transform(date, DATE_FORMAT);
          case 'date.addmonths(':
            date.setMonth(date.getMonth() + unitAmount);
            return this.datePipe.transform(date, DATE_FORMAT);
          case 'date.removemonths(':
            date.setMonth(date.getMonth() - unitAmount);
            return this.datePipe.transform(date, DATE_FORMAT);
          case 'date.addyears(':
            date.setFullYear(date.getFullYear() + unitAmount);
            return this.datePipe.transform(date, DATE_FORMAT);
          case 'date.removeyears(':
            date.setFullYear(date.getFullYear() - unitAmount);
            return this.datePipe.transform(date, DATE_FORMAT);
          default:
            throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [0], params.length));
        }
      default:
        if (func === 'date.now(') {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [0], params.length));
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
        }
    }
  }

  private performTimeFunction(func: string, params: string[], precision: number): string {

    switch (params.length) {
      case 0:
        if (func === 'time.now(') {
          return this.datePipe.transform(new Date(), TIME_FORMAT);
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
        }
      case 2:
        const time = parseTime(this.calculationService.evaluate(params[0], precision, ReturnType.STRING).value);
        const unitAmount = Number(this.calculationService.evaluate(params[1], precision, ReturnType.INT).value);
        switch (func) {
          case 'time.addseconds(':
            time.setSeconds(time.getSeconds() + unitAmount);
            return this.datePipe.transform(time, TIME_FORMAT);
          case 'time.removeseconds(':
            time.setSeconds(time.getSeconds() - unitAmount);
            return this.datePipe.transform(time, TIME_FORMAT);
          case 'time.addminutes(':
            time.setMinutes(time.getMinutes() + unitAmount);
            return this.datePipe.transform(time, TIME_FORMAT);
          case 'time.removeminutes(':
            time.setMinutes(time.getMinutes() - unitAmount);
            return this.datePipe.transform(time, TIME_FORMAT);
          case 'time.addhours(':
            time.setHours(time.getHours() + unitAmount);
            return this.datePipe.transform(time, TIME_FORMAT);
          case 'time.removehours(':
            time.setHours(time.getHours() - unitAmount);
            return this.datePipe.transform(time, TIME_FORMAT);
          default:
            throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [0], params.length));
        }
      default:
        if (func === 'date.now(') {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [0], params.length));
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
        }
    }
  }

  private performDateTimeFunction(func: string, params: string[], precision: number): string {

    switch (params.length) {
      case 0:
        if (func === 'datetime.now(') {
          return this.datePipe.transform(new Date(), DATETIME_FORMAT);
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
        }
      case 2:
        const dateTime = parseDateTime(this.calculationService.evaluate(params[0], precision, ReturnType.STRING).value);
        const unitAmount = Number(this.calculationService.evaluate(params[1], precision, ReturnType.INT).value);
        switch (func) {
          case 'datetime.addseconds(':
            dateTime.setSeconds(dateTime.getSeconds() + unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          case 'datetime.removeseconds(':
            dateTime.setSeconds(dateTime.getSeconds() - unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          case 'datetime.addminutes(':
            dateTime.setMinutes(dateTime.getMinutes() + unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          case 'datetime.removeminutes(':
            dateTime.setMinutes(dateTime.getMinutes() - unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          case 'datetime.addhours(':
            dateTime.setHours(dateTime.getHours() + unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          case 'datetime.removehours(':
            dateTime.setHours(dateTime.getHours() - unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          case 'datetime.adddays(':
            dateTime.setDate(dateTime.getDate() + unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          case 'datetime.removedays(':
            dateTime.setDate(dateTime.getDate() - unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          case 'datetime.addmonths(':
            dateTime.setMonth(dateTime.getMonth() + unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          case 'datetime.removemonths(':
            dateTime.setMonth(dateTime.getMonth() - unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          case 'datetime.addyears(':
            dateTime.setFullYear(dateTime.getFullYear() + unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          case 'datetime.removeyears(':
            dateTime.setFullYear(dateTime.getFullYear() - unitAmount);
            return this.datePipe.transform(dateTime, DATETIME_FORMAT);
          default:
            throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [0], params.length));
        }
      default:
        if (func === 'date.now(') {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [0], params.length));
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
        }
    }
  }

  private performSum(func: string, params: string[], precision: number): CalculationExpression {
    let value = 0;
    let isDouble = false;
    for (const param of params) {
      const expr = this.calculationService.evaluate(param, precision);
      switch (expr.type) {
        case ReturnType.DOUBLE:
          isDouble = true;
          value += Number(expr.value);
          break;
        case ReturnType.INT:
          value += Number(expr.value);
          break;
        default:
          throw new SyntaxError(`Trying to perform ${func.slice(0, -1).toUpperCase()} on non numerical parameter`);
      }
    }
    if (isDouble) {
      return new CalculationExpression(explicitDouble(value.toString()), ReturnType.DOUBLE);
    } else {
      return new CalculationExpression(value.toString(), ReturnType.INT);
    }
  }

  private performConcat(params: string[], precision: number): string {
    let result = '';
    for (const param of params) {
      const expr = this.calculationService.evaluate(param, precision, ReturnType.ANY, true);
      result += expr.value;
    }
    return result;
  }

  private performRoundFunction(func: string, params: string[], precision: number): number {
    if (params.length === 2) {
      const prec = Number(this.calculationService.evaluate(params[1], precision, ReturnType.INT).value);
      switch (func) {
        case 'round(':
          return roundIfNeeded(
            Number(this.calculationService.evaluate(params[0], prec, ReturnType.DOUBLE).value),
            true,
            prec
          );
        case 'round.low(':
          return roundIfNeeded(
            Number(this.calculationService.evaluate(params[0], prec, ReturnType.DOUBLE).value),
            true,
            prec,
            RoundType.LOW
          );
        case 'round.up(':
          return roundIfNeeded(
            Number(this.calculationService.evaluate(params[0], prec, ReturnType.DOUBLE).value),
            true,
            prec,
            RoundType.UP
          );
        default:
          throw new SyntaxError('Unknown function ' + func.slice(0, -1).toUpperCase());
      }
    } else {
      throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
    }
  }

  private performMinMax(func: string, params: string[], precision: number): CalculationExpression {
    if (params.length === 0) {
      throw new SyntaxError(`Function ${func.slice(0, -1).toUpperCase()} expects 1 or more arguments, got none`);
    } else {
      let result = this.calculationService.evaluate(params[0], precision);
      if ([ReturnType.INT, ReturnType.DOUBLE].indexOf(result.type) === -1) {
        throw new SyntaxError(`Function ${func.slice(0, -1).toUpperCase()} can only be performed on numerical parameters`);
      }
      for (let i = 1; i < params.length; i++) {
        const value = this.calculationService.evaluate(params[i], precision);
        if ([ReturnType.INT, ReturnType.DOUBLE].indexOf(value.type) === -1) {
          throw new SyntaxError(`Function ${func.slice(0, -1).toUpperCase()} can only be performed on numerical parameters`);
        } else if ((func === 'min(' && Number(value.value) < Number(result.value)) ||
          (func === 'max(' && Number(value.value) > Number(result.value))) {
          result = value;
        }
      }
      return result;
    }
  }

  private performRandom(func: string, params: string[], precision: number): CalculationExpression {
    switch (params.length) {
      case 0:
        return new CalculationExpression(explicitDouble(Math.random().toString()), ReturnType.DOUBLE);
      case 2:
        const min = Number(this.calculationService.evaluate(params[0], precision, ReturnType.INT).value);
        const max = Number(this.calculationService.evaluate(params[1], precision, ReturnType.INT).value);
        return new CalculationExpression(Math.floor(min + (max - min + 1) * Math.random()).toString(), ReturnType.INT);
      default:
        throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [0, 2], params.length));
    }
  }

  private performLogarithm(func: string, params: string[], precision: number): CalculationExpression {
    switch (params.length) {
      case 1:
        if (func === 'log(') {
          return new CalculationExpression(
            explicitDouble(Math.log10(Number(this.calculationService.evaluate(params[0], precision, ReturnType.DOUBLE).value)).toString()),
            ReturnType.DOUBLE
          );
        } else if (func === 'ln(') {
          return new CalculationExpression(
            explicitDouble(Math.log(Number(this.calculationService.evaluate(params[0], precision, ReturnType.DOUBLE).value)).toString()),
            ReturnType.DOUBLE
          );
        } else {
          throw new SyntaxError('Unknown syntax error on function ' + func.slice(0, -1).toUpperCase());
        }
      case 2:
        if (func === 'log(') {
          const nb = Number(this.calculationService.evaluate(params[0], precision, ReturnType.DOUBLE).value);
          const base = Number(this.calculationService.evaluate(params[1], precision, ReturnType.DOUBLE).value);
          return new CalculationExpression(explicitDouble((Math.log(nb) / Math.log(base)).toString()), ReturnType.DOUBLE);
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
        }
      default:
        if (func === 'log(') {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1, 2], params.length));
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
        }
    }
  }

  private performTrigo(func: string, params: string[], precision: number): CalculationExpression {
    if (params.length === 1) {
      let param = Number(this.calculationService.evaluate(params[0], precision, ReturnType.DOUBLE).value);
      if (['cos.deg(', 'sin.deg(', 'tan.deg('].indexOf(func) > -1) {
        // Degrees to radians
        param = Math.PI * param / 180;
      }
      let result: number;
      switch (func) {
        case 'cos(':
        case 'cos.deg(':
          result = Math.cos(param);
          break;
        case 'sin(':
        case 'sin.deg(':
          result = Math.sin(param);
          break;
        case 'tan(':
        case 'tan.deg(':
          result = Math.tan(param);
          break;
        case 'acos(':
        case 'acos.deg(':
          result = Math.acos(param);
          break;
        case 'asin(':
        case 'asin.deg(':
          result = Math.asin(param);
          break;
        case 'atan(':
        case 'atan.deg(':
          result = Math.atan(param);
          break;
        case 'cosh(':
          result = Math.cosh(param);
          break;
        case 'sinh(':
          result = Math.sinh(param);
          break;
        case 'tanh(':
          result = Math.tanh(param);
          break;
        case 'acosh(':
          result = Math.acosh(param);
          break;
        case 'asinh(':
          result = Math.asinh(param);
          break;
        case 'atanh(':
          result = Math.atanh(param);
          break;
        default:
          throw new SyntaxError('Unknown function ' + func.slice(0, -1).toUpperCase());
      }
      if (['acos.deg(', 'asin.deg(', 'atan.deg('].indexOf(func) > -1) {
        result = 180 * result / Math.PI;
      }
      return new CalculationExpression(explicitDouble(result.toString()), ReturnType.DOUBLE);
    } else {
      throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
    }
  }

  private performFunction(func: string, params: string[], precision: number, inQuote = false): CalculationExpression {
    switch (func) {
      case 'double(':
        if (params.length === 1) {
          const evaluatedExpression = this.calculationService.evaluate(params[0], precision, ReturnType.DOUBLE);
          evaluatedExpression.value = explicitDouble(roundIfNeeded(Number(evaluatedExpression.value), inQuote, precision).toString());
          return evaluatedExpression;
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
        }
      case 'string(':
        if (params.length === 1) {
          const evaluatedExpression = this.calculationService.evaluate(params[0], precision, ReturnType.STRING);
          evaluatedExpression.value = addQuotesIfNeeded(evaluatedExpression.value, inQuote);
          return evaluatedExpression;
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
        }
      case 'long(':
      case 'int(':
        if (params.length === 1) {
          const doubleValue = Number(this.calculationService.evaluate(params[0], precision, ReturnType.DOUBLE).value);
          return new CalculationExpression(Math.round(doubleValue).toString(), ReturnType.INT);
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
        }
      case 'egal(':
      case 'eq(':
      case 'lt(':
      case 'le(':
      case 'gt(':
      case 'ge(':
      case 'ne(':
        return new CalculationExpression(this.performComparisonFunction(func, params, precision).toString(), ReturnType.BOOLEAN);
      case 'et(':
        if (params.length === 2) {
          return new CalculationExpression(
            (
              isTruthy(this.calculationService.evaluate(params[0], precision)) &&
              isTruthy(this.calculationService.evaluate(params[1], precision))
            ).toString(),
            ReturnType.BOOLEAN);
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
        }
      case 'ou(':
        if (params.length === 2) {
          return new CalculationExpression(
            (
              isTruthy(this.calculationService.evaluate(params[0], precision)) ||
              isTruthy(this.calculationService.evaluate(params[1], precision))
            ).toString(),
            ReturnType.BOOLEAN);
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [2], params.length));
        }
      case 'si(':
        return this.performCondition(func, params, precision, inQuote);
      case 'sqrt(':
      case 'root(':
      case 'pow(':
        return this.performNumericalFunction(func, params, precision, inQuote);
      case 'droite(':
      case 'gauche(':
        return new CalculationExpression(addQuotesIfNeeded(this.performTextFunction(func, params, precision), inQuote), ReturnType.STRING);
      case 'date.now(':
      case 'date.adddays(':
      case 'date.removedays(':
      case 'date.addmonths(':
      case 'date.removemonths(':
      case 'date.addyears(':
      case 'date.removeyears(':
        return new CalculationExpression(addQuotesIfNeeded(this.performDateFunction(func, params, precision), inQuote), ReturnType.STRING);
      case 'time.now(':
      case 'time.addseconds(':
      case 'time.removeseconds(':
      case 'time.addminutes(':
      case 'time.removeminutes(':
      case 'time.addhours(':
      case 'time.removehours(':
        return new CalculationExpression(addQuotesIfNeeded(this.performTimeFunction(func, params, precision), inQuote), ReturnType.STRING);
      case 'datetime.now(':
      case 'datetime.addseconds(':
      case 'datetime.removeseconds(':
      case 'datetime.addminutes(':
      case 'datetime.removeminutes(':
      case 'datetime.addhours(':
      case 'datetime.removehours(':
      case 'datetime.adddays(':
      case 'datetime.removedays(':
      case 'datetime.addmonths(':
      case 'datetime.removemonths(':
      case 'datetime.addyears(':
      case 'datetime.removeyears(':
        return new CalculationExpression(
          addQuotesIfNeeded(this.performDateTimeFunction(func, params, precision), inQuote),
          ReturnType.STRING);
      case 'not(':
        if (params.length === 1) {
          return new CalculationExpression(
            (!isTruthy(this.calculationService.evaluate(params[0], precision))).toString(),
            ReturnType.BOOLEAN
          );
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
        }
      case 'somme(':
      case 'sum(':
        return this.performSum(func, params, precision);
      case 'concat(':
        return new CalculationExpression(this.performConcat(params, precision), ReturnType.STRING);
      case 'int.low(':
        if (params.length === 1) {
          const value = Math.floor(Number(this.calculationService.evaluate(params[0], precision, ReturnType.DOUBLE).value));
          return new CalculationExpression(value.toString(), ReturnType.INT);
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
        }
      case 'int.up(':
        if (params.length === 1) {
          const value = Math.ceil(Number(this.calculationService.evaluate(params[0], precision, ReturnType.DOUBLE).value));
          return new CalculationExpression(value.toString(), ReturnType.INT);
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [1], params.length));
        }
      case 'round(':
      case 'round.low(':
      case 'round.up(':
        return new CalculationExpression(explicitDouble(this.performRoundFunction(func, params, precision).toString()), ReturnType.DOUBLE);
      case 'min(':
      case 'max(':
        return this.performMinMax(func, params, precision);
      case 'random(':
        return this.performRandom(func, params, precision);
      case 'pi(':
        if (params.length === 0) {
          return new CalculationExpression(Math.PI.toString(), ReturnType.DOUBLE);
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [0], params.length));
        }
      case 'exp(':
        if (params.length === 1) {
          return new CalculationExpression(
            explicitDouble(Math.exp(Number(this.calculationService.evaluate(params[0], precision, ReturnType.DOUBLE).value)).toString()),
            ReturnType.DOUBLE
          );
        } else {
          throw new SyntaxError(this.wrongNumberOfArgumentsError(func, [0], params.length));
        }
      case 'log(':
      case 'ln(':
        return this.performLogarithm(func, params, precision);
      case 'cos(':
      case 'sin(':
      case 'tan(':
      case 'acos(':
      case 'asin(':
      case 'atan(':
      case 'cos.deg(':
      case 'sin.deg(':
      case 'tan.deg(':
      case 'acos.deg(':
      case 'asin.deg(':
      case 'atan.deg(':
      case 'cosh(':
      case 'sinh(':
      case 'tanh(':
      case 'acosh(':
      case 'asinh(':
      case 'atanh(':
        return this.performTrigo(func, params, precision);
      default:
        throw new SyntaxError('Unknown function ' + func.slice(0, -1).toUpperCase());
    }
  }

  private replaceFunction(expression: string, precision: number, func: string, index: number, inQuote = false): string {
    const offset = func.length;
    const endIndex = this.getEndFunctionIndex(expression.substring(index + offset)) + index + offset;
    const funcExpression = expression.substring(index + offset, endIndex);
    const evaluatedValue = this.performFunction(func, this.getParameters(funcExpression), precision, inQuote).value;
    return this.handleFunctions(expression.substring(0, index) + evaluatedValue + expression.substring(endIndex + 1), precision);
  }

  public handleFunctions(expression: string, precision: number): string {
    const allFunctions = functions;
    allFunctions.sort((a, b) => b.length - a.length);
    for (const func of allFunctions) {
      const offset = func.length;
      let currentQuotation: string;
      let inQuote = false;
      for (let i = 0; i < expression.length - offset; i++) {
        if (inQuote) {
          if (expression[i] === currentQuotation) {
            inQuote = false;
          } else if (expression.substring(i, i + offset).toLowerCase() === func.toLowerCase() && currentQuotation === '"') {
            return this.replaceFunction(expression, precision, func.toLowerCase(), i, true);
          }
        } else if (quotations.indexOf(expression[i]) > -1) {
          currentQuotation = expression[i];
          inQuote = true;
        } else if (expression.substring(i, i + offset).toLowerCase() === func.toLowerCase()) {
          return this.replaceFunction(expression, precision, func.toLowerCase(), i);
        }
      }
    }
    return expression.trim();
  }
}
