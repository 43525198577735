import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';

@NgModule({
  exports: [
    FormsModule,
    HttpClientModule,
    CommonModule
  ],
  providers: [
    Title
  ]
})
export class UdoSharedLibsModule { }
