
export class Fichier {
    constructor(
        public guid?: string,
        public name?: string,
        public extension?: string,
        public applicationId?: number,
        public type?: string,
        public size?: number,
        public context?: string,
        public blob?: Blob,
        public imageUrl?: string
    ) {
    }
}
