import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MapCoordinates } from '../models';
import { MAPBOX_TOKEN } from '../app.constants';

@Injectable()
export class MapService {

  constructor(
    private http: HttpClient
  ) { }

  geocode(address: string): Observable<any> {
    const url = 'https://nominatim.openstreetmap.org/search';
    const options = {
      params: {
        q: address,
        format: 'json',
        limit: '1'
      }
    };
    return this.http.get(url, options);
  }

  reverseGeocode(lat: string, lon: string): Observable<any> {
    const url = 'https://nominatim.openstreetmap.org/reverse';
    const options = {
      params: {
        format: 'json',
        lat,
        lon
      }
    };
    return this.http.get(url, options);
  }

  routeInfo(coordinates: MapCoordinates[]): Observable<any> {
    const baseUrl = 'https://api.mapbox.com/directions/v5/mapbox/driving/';
    let urlCoordinates = '';
    for (let index = 0; index < coordinates.length; index++) {
      if (index > 0) {
        urlCoordinates += ';';
      }
      urlCoordinates += `${coordinates[index].longitude},${coordinates[index].latitude}`;
    }
    return this.http.get(`${baseUrl}${urlCoordinates}.json?access_token=${MAPBOX_TOKEN}`);
  }

}
