export enum TypeObjetActionEnum {

    AJOUTER,
    RAFRAICHIR,
    SUPPRIMER,
    MODIFIER,
    COCHE_TOUT,
    DECOCHE_TOUT,
    PERIODE_SUIVANTE,
    PERIODE_PRECEDENTE,
    PERIODE_ACTUELLE,
    AFFICHAGE_SEMAINE,
    AFFICHAGE_MOIS,
    AFFICHAGE_HEURE,
    AFFICHAGE_JOUR,
    AFFICHAGE_ANNEE,
    AFFICHAGE_ONE_WEEK,
    AFFICHAGE_TWO_WEEK,
    AFFICHAGE_THREE_WEEK,
    AFFICHAGE_FOUR_WEEK
}
