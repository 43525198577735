import { Droit, TypeChampEnum, ZoneIhm } from '.';
import { ChampIhmOption } from './champ-ihm-option.model';

export class ChampIhm {
  constructor(
   public id?: number,
   public type?: TypeChampEnum,
   public visible?: boolean,
   public transparent?: boolean,
   public ordre?: number,
   public typedroitvisible?: number,
   public typedroitmodifiable?: number,
   public listChampIhmOptions?: Array<ChampIhmOption>,
   public listChampIhm?: Array<ChampIhm>,
   public listZoneIhm?: Array<ZoneIhm>,
      /*
   
   public listChampIhmOptions?: Array<ChampIhmOption>,
   public listMappingChamp?: Array<MappingChamp>,

   public listUserEvent?: Array<UserEvent>,
   public listUserVariable?: Array<UserVariable>,
   public listUserAction?: Array<UserAction>,
   */
   public listDroit?: Array<Droit>,
   public largeur?: number,
   public parentId?: number,
   public zoneZoneId?: number,
   public libelle?: string,
   public intitule?: string,
   public couleurfond?: string,
   public couleurpolice?: string,
   public ensaisie?: number,
   public largeurlibelle?: number,
   public positionlibelle?: string,
   public margeExterieureGauche?: number,
   public margeExterieureDroite?: number,
   public margeExterieureHaut?: number,
   public margeExterieureBas?: number,
   public flexgrow?: number,
   public flexdirection?: number,
   public flexalignitems?: number,
   public flexjustifycontent?: number,
   public zleft?: number,
   public zright?: number,
   public zindex?: number,
   public cursor?: number,
   public typehauteurzone?: number,
   public typeheight?: number,
   public pixelheight?: number,
   public pourcheight?: number,
   public typewidth?: number,
   public pixelwidth?: number,
   public pourcwidth?: number,
   public bleft?: number,
   public bright?: number,
   public btop?: number,
   public bbottom?: number,
   public bleftwidth?: number,
   public brightwidth?: number,
   public btopwidth?: number,
   public bbottomwidth?: number,
   public bleftcolor?: string,
   public brightcolor?: string,
   public btopcolor?: string,
   public bbottomcolor?: string,
   public btopleftradius?: number,
   public btopleftradiustype?: number,
   public btoprightradius?: number,
   public btoprightradiustype?: number,
   public bbottomleftradius?: number,
   public bbottomleftradiustype?: number,
   public bbottomrightradius?: number,
   public bbottomrightradiustype?: number,
   public bcouleurfond?: string,
   public calcpixelheight?: number,
   public calcpourcheight?: number,
   public calcpixelwidth?: number,
   public calcpourcwidth?: number,
  ) {
    this.listDroit = [];
    this.listChampIhm = [];
  }
}
