import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL, APP_ID } from '../app.constants';
import { AuthServerProvider } from '../shared';
import { Item, Utilisateur, UserQuery } from '../models';

@Injectable()
export class ItemService {

  private resourceUrl = `${API_URL}api/items`;

  constructor(private http: HttpClient, private authserv: AuthServerProvider) { }

  findAll(): Observable<Array<Item>> {
    const options = this.authserv.getOptions();
    return this.http.get<Array<Item>>(`${API_URL}api/tables`, { headers: options });
  }

  findAllQuery(): Observable<Array<UserQuery>> {
    const options = this.authserv.getOptions();
    return this.http.get<Array<Item>>(`${API_URL}api/userquery/all/${APP_ID}`, { headers: options });
  }

  findAllGroup(id): Observable<Array<any>> {
    const options = this.authserv.getOptions();
    return this.http.get<Array<Item>>(`${API_URL}api/utilisateur/groupofuser/${id}`, { headers: options });
  }

}
