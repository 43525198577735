import { Application, Item, Utilisateur } from '.';

export class AppInit {
  constructor(
    public applicationid: number,
    public type: number,
    public application?: Application,
    public listTable?: Array<Item>,
    public listUtilisateur?: Array<Utilisateur>,
    public userid?: number
    ) {
      this.listTable = [];
      this.listUtilisateur = [];
    }
  }
