import { ChampIhm, ChampNatif } from '.';

export class PrivateCell {
  constructor(
   public id?: number,
   public libelle?: string,
   public champNatif?: ChampNatif,
   public champIhm?: ChampIhm,
   public value?: string,
   public tableref?: string,
   public idChampNatif?: number,
   public estclef?: number,
   public type?: string,
   public champref?: string,
  ) { }
}
