export enum TypeUserActionEnum {
  IHM_FILTRE,
  AFFECTE_VALEUR,
  TEMPORISE,
  CHARGEMENT_OUVRE,
  CHARGEMENT_FERME,
  DBMODIFIE,
  ENREGISTREMENT_COURANT,
  ENREGISTREMENT_PARENT,
  ENREGISTREMENT_ENFANT,
  BOUCLE_DEBUT,
  BOUCLE_FIN,
  VARIABLE_DECLARE,
  VARIABLE_AFFECTE,
  VARIABLE_RECUPERE,
  ENR_LIT,
  SI,
  VARIABLE_INCREMENTE,
  LISTE_TAILLE,
  PROC_DEBUT,
  PROC_FIN,
  EXE_PROC,
  INFO,
  ERREUR,
  TABLE_SUPPRIME_SELECT,
  DBAJOUTE,
  DB_MAP_CHAMP,
  DATE_ACTUELLE,
  HEURE_ACTUELLE,
  UTILISATEUR_COURANT,
  UTILISATEUR_INFO,
  ENREGISTREMENT_INFO,
  CHAINE_CONSTRUIT,
  CHAINE_CONSTRUIT_ITEM,
  TABLE_RAFRAICHIT,
  CHRONO_DEBUT,
  CHRONO_FIN,
  SAISIE,
  TABLE_INDEX_COURANT,
  DBRECUPERE,
  DBFILTRE,
  DBRECUPEREPREMIER,
  TABLE_ECRIT,
  TABLE_MAP_COLONNE,
  LISTE_RAFRAICHIT,
  TABLE_EXE_ACTION,
  OUI_NON,
  SET_ENREGISTREMENT_COURANT,
  LISTE_SELECT,
  SET_CONTEXTE_COURANT,
  POPUP_OUVRE,
  POPUP_FERME,
  VALEUR_RECUPERE,
  MODIFIE_ATTRIBUT,
  FICHIER_SELECT,
  FILE_UPLOAD,
  FILE_INFO,
  FILE_RECUPERE,
  IMPRIME_ETAT,
  IMPRIME_ETAT_MAPPING,
  TABLE_ACTIVE_FILTRE,
  TABLE_DESACTIVE_FILTRE,
  CALCUL,
  TABLE_RECUPERE_LIGNES,
  LIGNE_INFO,
  LIGNE_VERS_ENREGISTREMENT,
  VARIABLE_AJOUTE,
  VARIABLE_SUPPRIME_TOUT,
  NAVIGATION_RETOUR,
  TABLE_LIGNE_SELECTIONNEE,
  CALL_FUNCTION,
  DATE_HEURE_ACTUELLE,
  ZR_EXE_ACTION,
  ZR_ACTIVE_FILTRE,
  ZR_DESACTIVE_FILTRE,
  NOTIFIER,
  NOTIFIER_UTILISATEURS,
  TABLE_EXPORTE,
  LOGOUT,
  FILE_DOWNLOAD,
  MOBILE_NAVIGUE,
  MOBILE_NAVIGUE_MAP,
  MOBILE_NAVIGUE_BACK,
  QUITTER,
  MOBILE_SCAN,
  ENREGISTREMENT_MAP,
  ENREGISTREMENT_MAP_ITEM,
  XML_VERS_DB,
  CALENDRIER_LIGNE_SELECTIONNEE,
  DATEHEURE_RECUPERE,
  CALENDRIER_INFO,
  MOBILE_SIGNATURE_ENVOI,
  MOBILE_SIGNATURE_RESET,
  APP_NAVIGUE,
  PAGE_NAVIGUE,
  CHAINE_RECUPERE,
  DATE_FORMATE,
  DATE_INFO,
  CHANGE_PERIODE,
  AGENDA_SELECT,
  DB_SUPPRIME,
  MAIL_ENVOI,
  CHANGE_ATTRIBUT,
  NOTIFICATIONPUBLISH,
  NOTIFICATIONSUBSCRIBE,
  MOBILE_VIEW_PDF,
  MOBILE_NOTIFICATION,
  MOBILE_TAKE_PICTURE,
  AFFECTE_MULTI_VALEUR,
  EXTRAIT_CHAINE,
  TABLE_RAFRAICHIT_LIGNE,
  ROUTE_INFO,
  DATA_EXPORT,
  MOBILE_RECORD_AUDIO,
  MOBILE_RECORD_VIDEO
}
