export class Filter {
  constructor(
   public id?: number,
   public ordre?: number,
   public parentId?: number,
   public libelle?: string,
   public idchampnatif?: number,
   public filtervalue?: string,
   public typefilter?: number,
   public originefilter?: number,
   public idtablesource?: number,
   public referencechamp?: string,
   public formatchamp?: string,
  ) { }
}
