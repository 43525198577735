export class TableCell {
  constructor(
   public id?: number,
   public champ?: any,
   public inputVisible?: boolean,
   public background?: any,
   public color?: any,
   public padding?: any,
   public value?: any,
   public typewidth?: string
  ) { }
}
