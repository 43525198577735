import { Injectable } from '@angular/core';
import { DateService, PrivateService, DataService, MapService, CalculationService, ApplicationService } from './';
import { PrivateRow, LocalFile } from '../models';
import { Observable } from 'rxjs';
import { LoginService } from '../shared';
import { Principal } from '../shared/auth/principal.service'
import { Router } from '@angular/router';
import { saveAs } from 'file-saver/FileSaver';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { APP_GUID, APP_ID } from '../app.constants'

@Injectable()
export class MainService {

  public dialogConfirmation: () => void;
  public dialogRejection: () => void;
  public fileSelection: (e) => void;
  public saisieConfirmation: (e) => void;
  public saisieRejection: (e) => void;
  
  constructor(
    private dateService: DateService,
    private privateService: PrivateService,
    private dataService: DataService,
    private loginService: LoginService,
    private principal: Principal,
    private router: Router,
    private mapService: MapService,
    private calcService: CalculationService,
    private appService: ApplicationService,
    private http: HttpClient
  ) { }

  confirm(message): Promise<boolean> {
    this.dataService.addPopup('confirm', message);
    return new Promise<boolean>((resolve, reject) => {
      this.dialogConfirmation = () => resolve(true);
      this.dialogRejection = () => resolve(false);
    });
  }

  saisie(message): Promise<any> {
    this.dataService.addPopup('saisie', message);
    return new Promise<any>((resolve, reject) => {
      this.saisieConfirmation = (e) => resolve({type: true, value: e});
      this.saisieRejection = (e) => resolve({type: false, value: e});
    });
  }

  selectFile(files): Promise<any> {
    event.preventDefault();
    const element: HTMLElement = document.getElementById('event-file-selector');
    element.setAttribute('accept', files);
    element.click();
    return new Promise<any>((resolve, reject) => {
      this.fileSelection = (e) => resolve(e);
    });
  }

  getLocalFile(guid: string): Observable<any> {
    const folderPath = `assets/img/guids/${guid}`;
    return this.http.get(folderPath);
  }

  public timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public dateverschaine(data) {
    return this.dateService.dateverschaine(data);
  }

  public heureverschaine(data) {
    return this.dateService.heureverschaine(data);
  }

  public DateToUs(data) {
    return this.dateService.DateToUs(data);
  }

  public DateToFr(data) {
    return this.dateService.DateToFr(data);
  }

  public getMonth(data) {
    return this.dateService.getMonth(data);
  }

  public getYear(data) {
    return this.dateService.getYear(data);
  }

  public getDate(data) {
    return this.dateService.getDate(data);
  }

  public getWeekDay(data) {
    return this.dateService.getWeekDay(data);
  }
  
  public getWeekNumber(data) {
    return this.dateService.getWeekNumber(data);
  }

  public incDate(dateSrc: any, type, inc) {
    return this.dateService.incDate(dateSrc, type, inc);
  }

  public incHeure(dateSrc: any, type, inc) {
    return this.dateService.incHeure(dateSrc, type, inc);
  }

  public getCurrentDateTime() {
    return this.dateService.getCurrentDateTime();
  }

  public getCurrentTime() {
    return this.dateService.getCurrentTime();
  }

  public CurrentDate() {
    return this.dateService.FrToUs(this.dateService.CurrentDate());
  }

  public getFirstDayOfWeek(data) {
    return this.dateService.getFirstDayOfWeek(data);
  }

  public getLastDayOfWeek(data) {
    return this.dateService.getLastDayOfWeek(data);
  }

  public getFirstDayOfMonth(data) {
    return this.dateService.getFirstDayOfMonth(data);
  }

  public getLastDayOfMonth(data) {
    return this.dateService.getLastDayOfMonth(data);
  }

  public addMultiPrivateRow(listprivateRow: PrivateRow[]): Observable<any> {
    return this.privateService.addMultiPrivateRow(listprivateRow);
  }

  public deleteMulti(listprivateRow: PrivateRow[]): Observable<boolean> {
    return this.privateService.deleteMulti(listprivateRow);
  }

  public callFunction(row: PrivateRow): Observable<boolean> {
    return this.privateService.callFunction(row);
  }

  public findAllUdoPrivateData(page: any): Observable<any> {
    return this.privateService.findAllUdoPrivateData(page);
  }

  public findApp(id: number): Observable<any> {
    return this.appService.findOneUdoAdmin(id);
  }

  public uploadFile(fichier: any): Observable<any> {
    return this.privateService.upload(fichier);
  }

  public downloadFile(fichier: any): Observable<any> {
    return this.privateService.download(fichier);
  }

  public mergePdf(fichier: any): Observable<any> {
    return this.privateService.mergePdf(fichier);
  }

  public generatePdf(generator: any): Observable<any> {
    return this.privateService.generatePdf(generator);
  }

  public sendMail(mail: any): Observable<any> {
    return this.privateService.sendMail(mail);
  }

  public createUser(user: any): Observable<any> {
    user.appguid = APP_GUID;
    return this.privateService.createUser(user);
  }

  public createSpecificUser(user: any): Observable<any> {
    user.appguid = APP_GUID;
    user.appid = APP_ID;
    return this.privateService.createSpecificUser(user);
  }

  public changePassword(user: any): Observable<any> {
    user.appguid = APP_GUID;
    user.appid = APP_ID;
    return this.privateService.changeSpecificUserPassword(user);
  }

  public blobToLocalFile(theBlob: Blob, fileName:string): LocalFile {
    const localFile = new LocalFile(this.blobToFile(theBlob, fileName));
    localFile.name = localFile.file.name;
    localFile.type = localFile.file.type;
    localFile.size = localFile.file.size;
    return localFile;
  }

  public blobToFile(theBlob: Blob, fileName:string): File {
    var b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return new File([b], fileName);
  }

  public provideFile(data: any) {
    saveAs(data.blob, data.name);
  }

  public currentUser() {
    return this.principal.getCurrentUser();
  }

  public logout() {
    this.loginService.logout();
  }

  public navigate(route) {
    this.router.navigate(['/'+route]);
  }

  public calculate(expression, precision): any {
    return this.calcService.evaluate(expression, precision);
  }

  public geocode(adress): Observable<any> {
    return this.mapService.geocode(adress);
  }

  public routeinfo(coordinates): Observable<any> {
    return this.mapService.routeInfo(coordinates);
  }

  public getOption(options: any, identifier: string): string {
    let option: any;

    if (undefined === options) {
      return '';
    }

    if (options.listChampIhmOptions) {
      option = options.listChampIhmOptions.find((f) => f.optionIdentifier === identifier);
    } else if (options.listChampEtatOptions) {
      option = options.listChampEtatOptions.find((f) => f.optionIdentifier === identifier);
    } else {
      option = options.find((f) => f.optionIdentifier === identifier);
    }

    if (undefined === option) {
      return '';
    }
    return option.optionValue;
  }
}
