import { ChampIhm } from '.';

export class ZoneIhm {
  constructor(
    public id?: number,
    public libelle?: string,
    public intitule?: string,
    public visible?: boolean,
    public transparent?: boolean,
    public ordre?: number,
    public largeur?: number,
    public listChampIhm?: Array<ChampIhm>,
    public parentId?: number,
    public corpsCouleurFond?: string,
    public corpsAlignementHorizontal?: number,
    public corpsAlignementVertical?: number,
    public headerVisible?: boolean,
    public headerCouleurFond?: string,
    public headerCouleurPolice?: string,
    public headerAlignementHorizontal?: string,
    public headerHauteur?: number,
    public idzoneecran?: number,
    public margeInterieureGauche?: number,
    public margeInterieureDroite?: number,
    public margeInterieureHaut?: number,
    public margeInterieureBas?: number,
    public margeExterieureGauche?: number,
    public margeExterieureDroite?: number,
    public margeExterieureHaut?: number,
    public margeExterieureBas?: number,
    public typehauteurzone?: number,
    public typeheight?: number,
    public pixelheight?: number,
    public pourcheight?: number,
    public typewidth?: number,
    public pixelwidth?: number,
    public pourcwidth?: number,
    public calcpixelheight?: number,
    public calcpourcheight?: number,
    public calcpixelwidth?: number,
    public calcpourcwidth?: number,
    public contexte?: number,
    public flexgrow?: number,
    public flexdirection?: number,
    public flexalignitems?: number,
    public flexjustifycontent?: number,
    public bleft?: number,
    public bright?: number,
    public btop?: number,
    public bbottom?: number,
    public bleftwidth?: number,
    public brightwidth?: number,
    public btopwidth?: number,
    public bbottomwidth?: number,
    public bleftcolor?: string,
    public brightcolor?: string,
    public btopcolor?: string,
    public bbottomcolor?: string,
    public btopleftradius?: number,
    public btopleftradiustype?: number,
    public btoprightradius?: number,
    public btoprightradiustype?: number,
    public bbottomleftradius?: number,
    public bbottomleftradiustype?: number,
    public bbottomrightradius?: number,
    public bbottomrightradiustype?: number,
    public bcouleurfond?: string,
    public typedroitvisible?: number,
    public typedroitmodifiable?: number,
    public idcontexte?: number,
    public iditemcontexte?: number,
    public variablesource?: string,
    public idtablesource?: number
  ) {
    this.listChampIhm = [];
  }
}
