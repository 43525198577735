import { Component, Input } from '@angular/core';
import { DataService, MainService } from '../../../services'

@Component({
  selector: 'event-saisie',
  styleUrls: ['../../../app.component.css'],
  template: `
    <div class="udo-pop" [style.z-index]="_altitude">
      <div class="udo-pop-dialog" style="min-width:300px;max-width:90%;background:white">
        <div class="udo-pop-title pop-title-blue">
          <span>{{msg}}</span>
        </div>
        <div class="udo-pop-content">
          <input [(ngModel)]="value">
        </div>
        <div class="udo-pop-bottom">
          <button class="btn btn-gris" (click)="cancel()">Annuler</button>
          <button class="btn btn-gris" (click)="valid()" style="background-color:rgba(13,87,136,.7)">Valider</button>
        </div>
      </div>
    </div>
  `
})
export class EventSaisieComponent {

  public msg: string;
  public _altitude;
  private _guid;
  public value;

  constructor(
    private dataService: DataService,
    private mainService: MainService
    ) {
  }

  @Input()
  public set altitude(altitude) {
    this._altitude = altitude;
  }

  @Input()
  public set guid(guid) {
    this._guid = guid;
  }

  @Input()
  public set param(param) {
    this.msg = param;
  }

  ngOnInit(): void {
  }

  cancel() {
    this.mainService.saisieRejection('');
    this.dataService.removePopup(this._guid);
  }

  valid() {
    this.mainService.saisieConfirmation(this.value);
    this.dataService.removePopup(this._guid);
  }

}
