import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'event-chargement',
  templateUrl: './event-chargement.component.html',
  styleUrls: [
    '../../../../app.component.css'
  ]
})
export class EventChargementComponent implements OnInit {

  public visible = false;
  public msg;
  public _altitude;
  public _guid;

  constructor() { }

  @Input()
  public set altitude(altitude) {
    this._altitude = altitude;
  }

  @Input()
  public set guid(guid) {
    this._guid = guid;
  }

  @Input()
  public set param(param) {
    this.msg = param;
  }

  ngOnInit(): void {
    this.visible = true;
  }
}
