import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[caldnd]'
})
export class CalDndDirective {

  public y: any;
  public drag = false;

  @Output()
  ondrag: EventEmitter<number> = new EventEmitter();
  @Output()
  ondragstart: EventEmitter<number> = new EventEmitter();
  @Output()
  ondragstop: EventEmitter<number> = new EventEmitter();

  @HostListener('document:mousemove', ['$event'])
  mousemove(evt): void {
    const delta = evt.pageY - this.y;
    if (this.drag === true) {
      this.ondrag.emit(delta);
    }
  }

  @HostListener('document:mouseup', ['$event'])
  mouseup(evt): void {
    if (this.drag === true) {
      this.drag = false;
      this.ondragstop.emit(evt);
    }
  }

  @HostListener('mousedown', ['$event'])
  public onmousedown(evt): void {
    // Prevent default dragging of selected content
    evt.preventDefault();
    this.y = evt.pageY;
    this.drag = true;
    this.ondragstart.emit(1);
  }

}
