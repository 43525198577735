export enum TypeEgaliteEnum {

    EGALE,
    DIFFERENT,
    SUPERIEUR,
    SUPERIEUREGALE,
    INFERIEUR,
    INFERIEUREGALE,
    ENTRE,
    COMMENCE_PAR,
    TERMINE_PAR,
    CONTIENT,
    CONTIENT_PAS,
    EGALITE_MULTIPLE,
    FILS,
    PERE

}
