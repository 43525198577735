import { Component, Input } from '@angular/core';
import { DataService } from '../../../services'

@Component({
  selector: 'event-info',
  styleUrls: ['../../../app.component.css'],
  template: `
    <div class="udo-pop" [style.z-index]="_altitude">
      <div class="udo-pop-dialog" style="min-width:300px;max-width:90%;background:white">
        <div class="udo-pop-title pop-title-blue">
          <i class="ion-icons ion-information-circled" style="margin-right:10px;font-size:30px" aria-hidden="true"></i>
          <span>Info</span>
        </div>
        <div class="udo-pop-content">
          <span class="info">{{msg}}</span>
        </div>
        <div class="udo-pop-bottom">
          <button class="btn btn-gris" (click)="close()">Fermer</button>
        </div>
      </div>
    </div>
  `
})
export class EventInfoComponent {

  public msg: string;
  public _altitude;
  private _guid;

  constructor(private dataService: DataService) {
  }

  @Input()
  public set altitude(altitude) {
    this._altitude = altitude;
  }

  @Input()
  public set guid(guid) {
    this._guid = guid;
  }

  @Input()
  public set param(param) {
    this.msg = param;
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dataService.removePopup(this._guid);
  }

}
