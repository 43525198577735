import { TableCell } from '.';

export class TableRow {
  constructor(
   public datarow?: any,
   public listCell?: Array<TableCell>,
   public checked?: boolean,
   public deroul?: boolean,
   public visible?: boolean,
   public index?: number,
   public cochebackground?: any,
   public enteteborderwidth?: any,
   public enteteborderstyle?: any,
   public entetebordercolor?: any,
  ) {
  }
}
