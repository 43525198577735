export enum TypeChampEnum {
  LABEL,
  COMBO,
  INPUT_TEXT,
  INPUT_NUMBER,
  INPUT_TEXT_BUTTON,
  DATE,
  MAPPING,
  TABLEAU,
  AJOUT_MULTILIGNE,
  CALENDRIER,
  PLANNING_OLD,
  NAVIGATION_APPLICATION,
  SPLITTER,
  TCD,
  TCD_FILTRES,
  TCD_LIGNES,
  TCD_COLONNES,
  TCD_VALEURS,
  TCD_FILTRES_VALEURS,
  TRIE,
  COLONNE,
  OPTION_APP,
  CARTE,
  CALENDRIER_COULEUR,
  GRAPH,
  MAPPING_CHAMP,
  MAPPING_ITEM,
  MAPPING_CHAMP_CLEF,
  MAPPING_XML_BALISE,
  MAPPING_XML_ATTRIBUT,
  LISTE,
  FILTRE,
  MOBILE_LISTE_L,
  MOBILE_CARD,
  MOBILE_LISTE_CONTENEUR,
  MOBILE_INPUT,
  MOBILE_INPUT_DATE,
  MOBILE_IMAGE,
  MAPPING_XML_ATTRIBUT_CLEF,
  IMAGE,
  CALENDRIER_INFOBULLE_ITEM,
  CALENDRIER_INFOBULLE_CHAMP,
  MFC,
  GRAPH_SERIE,
  BUTTON,
  CHRONO,
  COLONNE_ICON_MAPPING,
  HEADER_TITLE,
  PIPELINE,
  TIMELINE,
  TABLE_BUTTON,
  INPUT,
  STEP_WIZARD,
  STEP_WIZARD_PAGE,
  ONGLET,
  VOLET,
  POPUP,
  MENU_DEROULANT,
  MENU_OPTION,
  OPTION_ITEM,
  CHECKBOX,
  TOGGLE,
  ZONE,
  HEADER,
  ITEM_NAV,
  ZONE_REPETEE,
  SIDEBAR,
  ZONE_REPETEE_MAPPING,
  COPY,
  MOBILE_PAGE,
  MOBILE_LAYOUT,
  MOBILE_LISTE,
  MOBILE_LABEL,
  MOBILE_PAGE_VARIABLE,
  MOBILE_DATA_FORM,
  MOBILE_DATA_PROPERTY,
  MOBILE_BUTTON,
  MOBILE_ICON,
  ICON,
  MOBILE_MODAL,
  MOBILE_DROPDOWN,
  MOBILE_IMG,
  MOBILE_IMAGE_MAPPER,
  MOBILE_SCROLL_VIEW,
  MOBILE_TOGGLE,
  PAGE,
  SPLIT_AREA,
  MOBILE_DRAWING,
  PROPERTY_WINDOW,
  DROPDOWN,
  AGENDA,
  CALENDRIER_TACHE,
  INPUT_TIME,
  ROUTE,
  GANTT,
  GANTT_TACHE,
  MOBILE_TABVIEW,
  MOBILE_TABVIEW_ITEM,
  PLANNING,
  PLANNING_TACHE,
  PLANNING_COLONNE,
  COLOR_PICKER,
  MENU_SEPARATEUR,
  MOBILE_MAP,
  MOBILE_ROUTE,
  SIDEB,
  PLAYER_AUDIO,
  PLAYER_VIDEO,
  PLAYER_AUDIO_MOBILE,
  PLAYER_VIDEO_MOBILE
}
