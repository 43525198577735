import { Item, Filter, Sort } from '.';

export class Pager {

  public id: number;
  public parentId: number;
  public libelle: string;
  public arbre: string;
  public reference: string;
  public applicationId?: number;
  public applicationRef: string;
  public itemId?: number;
  public idobjetihm?: number;
  public page?: number;
  public pagesize?: number;
  public data?: any;
  public listFilter?: Array<Filter>;
  public listSort?: Array<Sort>;
  public listPage?: Array<number>;
  // public listOptions?: Array<UserAction>;
  public startPage?: number;
  public endPage?: number;
  public idcontext?: number;
  public iditemfiltre?: number;
  public idcurrentitem?: number;
  public idcurrentrow?: number;
  // public champihm?: ChampIhm;
  public listData?: any;
  public item?: Item;
  public itemgantt?: Item;
  public refchamptable?: string;
  public refchampgantt?: string;
  public datagantt?: string;

  constructor() {
    this.data = [];
    // this.listFilter = [];
    // this.listOptions = [];
  }
}
