import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
/*import { CookieService } from 'ngx-cookie';*/
import { UdoSharedLibsModule, AuthServerProvider, AccountService, StateStorageService, LoginService, Principal, } from './';
import { GanttDndDirective } from '../directives';

@NgModule({
  imports: [
    UdoSharedLibsModule
  ],
  declarations: [
    GanttDndDirective
  ],
  providers: [
    /*CookieService,*/
    LoginService,
    AccountService,
    StateStorageService,
    Principal,
    AuthServerProvider,
    DatePipe
  ],
  exports: [
    DatePipe,
    GanttDndDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UdoSharedModule { }
